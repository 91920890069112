<template>
  <div class="map-container">
    <div id="map" ref="mapContainer"></div>
    <LayerSelector @layer-selected="changeLayer" />
    <div class="bottom-controls">
      <TimeSlider 
        :timestamps="timestamps" 
        @timestamp-selected="changeTimestamp" 
        v-if="timestamps.length"
        class="time-slider"
      />
      <Legend 
        :legendData="currentLegendData" 
        v-if="currentLegendData"
        class="legend"
      />
    </div>
    <ValueMarker
      v-if="markerData && markerData.position"
      :position="markerData.position"
      :value="markerData.value"
      :unit="currentLegendData?.unit || ''"
      :mapHeight="mapHeight"
      @close-marker="markerData = null"
    />
  </div>
</template>

<script>
import { onMounted, ref, nextTick } from 'vue';
// ---- Remove maplibre-gl imports ----
// import maplibregl from 'maplibre-gl';
// import 'maplibre-gl/dist/maplibre-gl.css';

// ---- Add mapbox-gl imports ----
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import LayerSelector from './LayerSelector.vue';
import Legend from './Legend.vue';
import TimeSlider from './TimeSlider.vue';
import ValueMarker from './ValueMarker.vue';

export default {
  components: {
    LayerSelector,
    Legend,
    TimeSlider,
    ValueMarker
  },
  setup() {
    const mapContainer = ref(null);
    let map = null;

    const currentLayerDescription = ref(null);
    const currentLegendData = ref(null);
    const timestamps = ref([]);
    const currentTimestamp = ref(null);
    const layerUrl = ref('');
    const markerData = ref(null);
    const mapHeight = ref(0);

    // Buffering configuration
    let currentTimestampIndex = -1;
    const cacheForward = 2;
    const cacheBackward = 1;
    let layers = {}; // stores added layers keyed by timestamp

    // Opacity values
    const opacityValue = 0.5;
    const getOpacityPaintProperty = (type) => {
      if (type === 'symbol') {
        return 'text-opacity';
      } else {
        return type + '-opacity';
      }
    };
    const getOpacityValue = (type) => {
      if (type === 'symbol') {
        return 1;
      } else {
        return opacityValue;
      }
    };

    // ---- Add your Mapbox token here ----
    mapboxgl.accessToken = 'pk.eyJ1IjoiamF3b3Jza2l3b2oiLCJhIjoiY2pldG1zYjBwMDI2YTJ4bnpnbjBrYTJ6bSJ9.DXTExrussJkU_JxtpLPSzg';

    // Fetch layer info from your API
    const fetchLayerInfo = async (layerId) => {
      const descriptionResponse = await fetch(`https://api.dev.expectedweather.com/description/${layerId}`);
      const description = await descriptionResponse.json();

      const timestampsResponse = await fetch(`https://api.dev.expectedweather.com/timestamps/${layerId}`);
      const timestampsData = await timestampsResponse.json();
      timestamps.value = timestampsData.timestamps;
      currentTimestamp.value = timestamps.value[0];
      layerUrl.value = timestampsData.url;

      currentLayerDescription.value = description;
      currentLegendData.value = description.legend;

      return { description, timestampsData };
    };

    // Instead of single addWeatherLayer, use one layer per timestamp
    const addLayer = (timestamp, description) => {
      if (layers[timestamp]) {
        return; // already added
      }
      const sourceUrl = layerUrl.value.replace('${timestamp}', timestamp);

      // Add a dedicated source for this timestamp
      map.addSource(timestamp, {
        type: description.sourceType || 'vector',
        tiles: [sourceUrl],
        minzoom: description.minZoom,
        maxzoom: description.maxZoom
      });

      let layerDef = {
        id: timestamp,
        type: description.type,
        source: timestamp,
        'source-layer': 'vector'
      };

      // Start with 0.0 opacity
      let paintStyle = {};
      if (description.type === 'fill') {
        paintStyle = {
          'fill-color': description.style.paint['fill-color'],
          'fill-opacity': 0.0,
          'fill-opacity-transition': { duration: 0, delay: 0 }
        };
      } else if (description.type === 'raster') {
        paintStyle = {
          'raster-opacity': 0.0,
          'raster-opacity-transition': { duration: 0, delay: 0 }
        };
      } else if (description.type === 'line') {
        paintStyle = {
          'line-opacity': 0.0,
          'line-opacity-transition': { duration: 0, delay: 0 }
        };
      } else if (description.type === 'symbol') {
        paintStyle = {
          'text-opacity': 0.0,
          'text-opacity-transition': { duration: 0, delay: 0 },
          'icon-opacity': 0.0,
          'icon-opacity-transition': { duration: 0, delay: 0 }
        };
      }

      layerDef.paint = { ...paintStyle };

      map.addLayer(layerDef);
      layers[timestamp] = layerDef;
    };

    const removeLayer = (timestamp) => {
      if (!layers[timestamp]) return;
      if (map.getLayer(timestamp)) {
        map.removeLayer(timestamp);
      }
      if (map.getSource(timestamp)) {
        map.removeSource(timestamp);
      }
      delete layers[timestamp];
    };

    const calculateCurrentTimestampIndex = () => {
      if (currentTimestampIndex === -1) {
        const currentDate = new Date();
        const currentTime = currentDate.valueOf() + currentDate.getTimezoneOffset() * 60 * 1000;

        let minIndex = 0;
        let minValue = Infinity;

        for (let i = 0; i < timestamps.value.length; i++) {
          const timestamp = timestamps.value[i];
          const year = parseInt(timestamp.substring(0, 4));
          const month = parseInt(timestamp.substring(4, 6)) - 1;
          const day = parseInt(timestamp.substring(6, 8));
          const hour = parseInt(timestamp.substring(9, 11));
          const minute = parseInt(timestamp.substring(11, 13));
          const second = parseInt(timestamp.substring(13, 15));
          const timestampDate = Date.UTC(year, month, day, hour, minute, second);
          const diff = Math.abs(currentTime - timestampDate);
          if (diff < minValue) {
            minValue = diff;
            minIndex = i;
          }
        }

        currentTimestampIndex = minIndex;
      }
    };

    const addLayers = () => {
      const backwardIndex = Math.max(currentTimestampIndex - cacheBackward, 0);
      const forwardIndex = Math.min(currentTimestampIndex + cacheForward, timestamps.value.length - 1);

      for (let i = 0; i < timestamps.value.length; i++) {
        const timestamp = timestamps.value[i];
        if (i >= backwardIndex && i <= forwardIndex) {
          addLayer(timestamp, currentLayerDescription.value);
          const prop = getOpacityPaintProperty(currentLayerDescription.value.type);
          const val = i === currentTimestampIndex ? getOpacityValue(currentLayerDescription.value.type) : 0.0;
          map.setPaintProperty(timestamp, prop, val);
        } else {
          removeLayer(timestamp);
        }
      }
    };

    const updateLayerTimestamps = () => {
      const backwardIndex = Math.max(currentTimestampIndex - cacheBackward, 0);
      const forwardIndex = Math.min(currentTimestampIndex + cacheForward, timestamps.value.length - 1);

      for (let i = 0; i < timestamps.value.length; i++) {
        const timestamp = timestamps.value[i];
        if (i >= backwardIndex && i <= forwardIndex) {
          addLayer(timestamp, currentLayerDescription.value);
        } else {
          removeLayer(timestamp);
        }

        if (layers[timestamp]) {
          const prop = getOpacityPaintProperty(currentLayerDescription.value.type);
          const val = (i === currentTimestampIndex) ? getOpacityValue(currentLayerDescription.value.type) : 0.0;
          map.setPaintProperty(timestamp, prop, val);
        }
      }
    };

    const selectLayer = () => {
      // Clear existing layers
      for (const t of Object.keys(layers)) {
        removeLayer(t);
      }
      calculateCurrentTimestampIndex();
      addLayers();
    };

    const selectTimestampIndex = (index) => {
      currentTimestampIndex = index;
      updateLayerTimestamps();
    };

    const changeLayer = async (layer) => {
      await fetchLayerInfo(layer.id);
      selectLayer();
      updateMarkerValue();
    };

    const changeTimestamp = (timestamp) => {
      const idx = timestamps.value.indexOf(timestamp);
      if (idx !== -1) {
        selectTimestampIndex(idx);
      }
      // Update marker after the map re-renders
      map.once('idle', () => {
        updateMarkerValue();
      });
    };

    const handleMapClick = async (e) => {
      const features = map.queryRenderedFeatures(e.point);
      const visibleFeatures = features.filter(f => layers[f.layer.id]);
      const feature = visibleFeatures[0];
      if (feature && feature.properties.value) {
        markerData.value = {
          lngLat: e.lngLat,
          value: feature.properties.value
        };
      } else {
        markerData.value = {
          lngLat: e.lngLat,
          value: ''
        };
      }
      await nextTick();
      updateMarkerScreenPosition();
    };

    const updateMarkerScreenPosition = () => {
      if (!markerData.value || !markerData.value.lngLat) return;
      const point = map.project(markerData.value.lngLat);
      markerData.value = {
        ...markerData.value,
        position: { x: point.x, y: point.y }
      };
    };

    const updateMarkerValue = async () => {
      if (markerData.value && markerData.value.lngLat) {
        const point = map.project(markerData.value.lngLat);
        const features = map.queryRenderedFeatures(point);
        const visibleFeatures = features.filter(f => layers[f.layer.id]);
        const feature = visibleFeatures[0];

        if (feature && feature.properties.value) {
          markerData.value = {
            ...markerData.value,
            value: feature.properties.value
          };
        } else {
          markerData.value = {
            ...markerData.value,
            value: ''
          };
        }
        updateMarkerScreenPosition();
      }
    };

    onMounted(async () => {
      map = new mapboxgl.Map({
        container: mapContainer.value,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [0, 25],
        zoom: 2.5,
        attributionControl: false
      });

      // If you want the globe projection (like in your React code):
      // map.setProjection('globe');

      map.addControl(new mapboxgl.AttributionControl({ compact: true }), 'top-left');

      // Load initial layer
      const initialLayer = 'VECTOR.gfs_0.25_air_temperature_2m.default';
      await fetchLayerInfo(initialLayer);
      selectLayer();

      map.on('load', () => {
        map.on('click', handleMapClick);
        map.on('move', updateMarkerScreenPosition);
        map.on('zoom', updateMarkerScreenPosition);
      });

      // Also update the marker on move/zoom
      map.on('move', updateMarkerScreenPosition);
      map.on('zoom', updateMarkerScreenPosition);

      await nextTick();
      mapHeight.value = mapContainer.value.clientHeight;
    });

    return {
      mapContainer,
      changeLayer,
      changeTimestamp,
      currentLegendData,
      timestamps,
      markerData,
      mapHeight
    };
  }
};
</script>

<style scoped>
.map-container {
  position: relative;
  width: 100%;
  height: 100vh;
}

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.bottom-controls {
  position: absolute;
  bottom: 5px;
  left: 20px;
  right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.time-slider {
  width: 83%;
}

.legend {
  width: 15%;
  margin-bottom: 25px;
}

/* Adjust these if you want to style the Mapbox attribution */
:deep(.mapboxgl-ctrl-top-left .mapboxgl-ctrl-attrib) {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 0 5px;
  margin: 10px;
  border-radius: 3px;
  font-size: 10px;
}
:deep(.mapboxgl-ctrl-top-left .mapboxgl-ctrl-attrib-button) {
  display: none;
}
</style>