<template>
  <div class="layer-selector">
    <ul>
      <li 
        v-for="layer in layers" 
        :key="layer.id" 
        @click="selectLayer(layer)"
        :class="{ 'selected': layer.id === selectedLayerId }"
      >
        <div class="layer-item">
          <span class="layer-name" ref="layerNames">{{ layer.name }}</span>
          <div class="icon-wrapper">
            <img :src="`/icons/${layer.id}.png`" :alt="`${layer.name} icon`" class="layer-icon" />
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { ref, onMounted, nextTick } from 'vue';

export default {
  name: 'LayerSelector',
  emits: ['layer-selected'],
  setup(props, { emit }) {
    const layers = ref([]);
    const selectedLayerId = ref('');
    const layerNames = ref([]);

    const fetchLayers = async () => {
      try {
        const response = await fetch("https://api.dev.expectedweather.com/layer?clientId=expectedWeather");
        layers.value = await response.json();
        if (layers.value.length > 0) {
          selectLayer(layers.value[0]);
        }
        await nextTick();
        adjustLayerSizes();
      } catch (error) {
        console.error("Error fetching layers:", error);
      }
    };

    const selectLayer = (layer) => {
      selectedLayerId.value = layer.id;
      emit('layer-selected', layer);
    };

    const adjustLayerSizes = () => {
      const maxWidth = Math.max(...layerNames.value.map(el => el.offsetWidth));
      layerNames.value.forEach(el => {
        el.closest('.layer-item').style.width = `${maxWidth + 60}px`; // 60px for padding and icon
      });
    };

    onMounted(fetchLayers);

    return {
      layers,
      selectLayer,
      selectedLayerId,
      layerNames
    };
  }
}
</script>



<style scoped>
.layer-selector {
  position: absolute;
  top: 10px;
  right: 10px;
  max-height: 80vh;
  overflow-y: auto;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  margin-bottom: 4px; /* Reduced from 5px */
}

.layer-item {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 4px 8px; /* Reduced from 8px 12px */
  border-radius: 3px; /* Slightly reduced from 4px */
  cursor: pointer;
  transition: all 0.3s ease;
  box-sizing: border-box;
}

.layer-item:hover {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Reduced shadow */
}

li.selected .layer-item {
  background-color: rgba(80, 80, 80, 0.8);
  color: white;
}

.layer-name {
  flex-grow: 1;
  margin-right: 8px; /* Slightly reduced from 10px */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.9em; /* Slightly reduced font size */
}

.icon-wrapper {
  width: 20px; /* Reduced from 24px */
  height: 20px; /* Reduced from 24px */
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #ccc;
  flex-shrink: 0;
}

.layer-icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>